<template>
  <div v-if="!isLoading">
    <!-- Full page layouts for unauthenticated views -->
    <router-view v-if="!appUser.isAuthenticated" />

    <!-- Authenticated pages -->
    <div v-else class="app" :class="appClasses">
      <vue3-progress-bar />
      <AppHeader v-if="!appOption.appHeaderHide" />
      <AppSidebar v-if="!appOption.appSidebarHide" />
      <div class="app-content" :class="appOption.appContentClass">
        <router-view />
      </div>
      <AppFooter v-if="appOption.appFooter" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  useProgress,
  type ProgressFinisher,
} from "@marcoschulte/vue3-progress";
import router from "./router";
import { useAppOptionStore } from "@/stores/app-option";
import { useAppUserStore } from "@/stores/app-user";
import { useAppDataStore } from "@/stores/app-data";
import AppSidebar from "@/components/app/Sidebar.vue";
import AppHeader from "@/components/app/Header.vue";
import AppFooter from "@/components/app/Footer.vue";

export default defineComponent({
  setup() {
    return {
      appOption: useAppOptionStore(),
      appUser: useAppUserStore(),
      appData: useAppDataStore(),
    };
  },
  created() {
    this.registerRouterHooks();
    document.querySelector("body")?.classList.add("app-init");
    this.isLoading = false;
  },
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    isSandbox() {
      return import.meta.env.VITE_APP_STAGE === "stage";
    },
    appClasses() {
      const { appOption, isSandbox } = this;

      return {
        "app-header-menu-search-toggled": appOption.appHeaderSearchToggled,
        "app-sidebar-minified": appOption.appSidebarMinified,
        "app-sidebar-collapsed": appOption.appSidebarCollapsed,
        "app-sidebar-mobile-toggled": appOption.appSidebarMobileToggled,
        "app-sidebar-mobile-closed": appOption.appSidebarMobileClosed,
        "app-content-full-height": appOption.appContentFullHeight,
        "app-content-full-width": appOption.appSidebarHide,
        "app-without-sidebar": appOption.appSidebarHide,
        "app-without-header": appOption.appHeaderHide,
        "app-boxed-layout": appOption.appBoxedLayout,
        "app-footer-fixed": appOption.appFooterFixed,
        sandbox: isSandbox,
      };
    },
  },
  methods: {
    registerRouterHooks() {
      const progresses = [] as ProgressFinisher[];

      router.beforeEach(async () => {
        progresses.push(useProgress().start());

        this.appOption.appSidebarMobileToggled = false;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        const targetElm = [].slice.call(
          document.querySelectorAll(".app-sidebar .menu-submenu"),
        );

        targetElm.map(function (elm: HTMLElement) {
          elm.style.display = "";
        });
      });

      router.afterEach(async () => {
        progresses.pop()?.finish();
      });
    },
  },
});
</script>
