import type { RouteLocationNormalized } from "vue-router";
import { canPerformAction, type PermissionsMap } from "@parkingmgt/iam-sdk-ts";
import { getAvailableReports } from "@/helpers/reporting";
import { useAppUserStore } from "@/stores/app-user";
import {
  USE_REVENUE_DASHBOARD,
  USE_FRONTDESK_DASHBOARD,
} from "@/helpers/constants";

export default function permissionsGuard(to: RouteLocationNormalized) {
  const appUserStore = useAppUserStore();

  if (!appUserStore.user?.permissions) {
    return { name: "Login" };
  }

  const permissions = appUserStore.user
    .permissions as unknown as PermissionsMap;

  switch (to.name) {
    case "Dashboard":
      if (!canPerformAction(USE_REVENUE_DASHBOARD, permissions)) {
        return { name: "Login" };
      }
      break;

    case "FrontDesk":
      if (!canPerformAction(USE_FRONTDESK_DASHBOARD, permissions)) {
        return { name: "Dashboard" };
      }
      break;

    case "Reporting":
      if (!getAvailableReports()?.length) {
        return { name: "Dashboard" };
      }
      break;
  }
}
