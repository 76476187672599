import _availableReports from "@/assets/json/availableReports.json";
import { useAppUserStore } from "@/stores/app-user";

export interface Report {
  name: string;
  title: string;
  description: string;
  permission: string;
  canBeScheduled: boolean;
  availableFormats: string[];
  availableFilters: {
    key: string;
    label: string;
    options: {
      [k: string]: {
        operator: string;
        label: string;
        field?: string;
        options?: { key: string; label: string }[] | string[];
      };
    };
  }[];
}

export const getAvailableReports = (): Report[] => {
  const userStore = useAppUserStore();
  const reportsConfig = _availableReports as Report[];
  const availableReports: Report[] = [];

  for (const report of reportsConfig) {
    if (userStore.permissions?.[report.permission]) {
      report.availableFilters = report.availableFilters.map((filter) => {
        if (filter.key === "locationId") {
          filter.options.equals.options = userStore.permissions[
            report.permission
          ].map((location): { key: string; label: string } => ({
            key: location.locationId,
            label: location.name,
          }));
        }

        return filter;
      });

      availableReports.push(report);
    }
  }

  return availableReports;
};
