import { defineStore } from "pinia";
import { canPerformAction, type PermissionsMap } from "@parkingmgt/iam-sdk-ts";
import { getAvailableReports } from "@/helpers/reporting";
import { useAppUserStore } from "@/stores/app-user";
import {
  USE_REVENUE_DASHBOARD,
  USE_FRONTDESK_DASHBOARD,
} from "@/helpers/constants";

export const useAppSidebarMenuStore = defineStore({
  id: "appSidebarMenu",
  state: () => {
    const appUserStore = useAppUserStore();

    const userPermissions =
      appUserStore.permissions as unknown as PermissionsMap;

    return [
      // NOTE: Keeping for future reference.
      // {
      //   text: "Menu",
      //   is_header: true,
      // },
      {
        url: "/",
        icon: "fa-solid fa-display",
        text: "Dashboard",
        canAccess: () =>
          canPerformAction(USE_REVENUE_DASHBOARD, userPermissions),
      },
      {
        url: "/front-desk",
        icon: "fa-solid fa-building-circle-check",
        text: "FrontDesk",
        canAccess: () =>
          canPerformAction(USE_FRONTDESK_DASHBOARD, userPermissions),
      },
      {
        url: "/reporting",
        icon: "fa fa-database",
        text: "Reporting",
        canAccess: (): boolean => getAvailableReports()?.length > 0,
      },
      // NOTE: Keeping for future reference.
      // {
      //   is_divider: true,
      // },
    ];
  },
});
