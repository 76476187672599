import axios from "axios";
import { defineStore } from "pinia";
import { useAppDataStore } from "@/stores/app-data";

const client = axios.create({ baseURL: import.meta.env.VITE_API_BASE_URL });

export interface AppUserStoreUser {
  attributes: {
    id: string;
    name: string;
    email: string;
  };
  permissions: UserPermissionMap;
}

export interface AppUserStoreState {
  user: AppUserStoreUser | null;
  accessToken: string;
  refreshToken: string;
  [k: string]: any;
}

export interface UserPermissionMap {
  [key: string]: PMC.Parking.Location[];
}

export const useAppUserStore = defineStore({
  id: "appUser",
  state: (): AppUserStoreState => {
    return {
      user: null,
      accessToken: "",
      refreshToken: "",
    };
  },
  actions: {
    setUserAndTokens(
      user: AppUserStoreUser | null,
      accessToken: string,
      refreshToken: string,
    ) {
      this.user = user;
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
    },
    setPermissions(permissions: UserPermissionMap) {
      if (!this.user) {
        throw new Error("User not set");
      }

      this.user.permissions = permissions;
    },
    postStartAuthentication(email: string) {
      return client.post("/v1/auth/startAuthentication", { email });
    },
    async postRequestToken(email: string, otp: string) {
      const res = await client.post("/v1/auth/requestToken", { email, otp });

      const { user, accessToken, refreshToken, permissions } = res.data.data;

      this.setUserAndTokens(
        {
          attributes: user,
          permissions,
        },
        accessToken,
        refreshToken,
      );

      return Promise.resolve();
    },
    async postRefreshToken() {
      if (!this.refreshToken) {
        return Promise.reject(new Error("No refresh token available"));
      }

      const { data } = await client
        .post("/v1/auth/refreshToken", {
          refreshToken: this.refreshToken,
        })
        .catch(() => ({ data: {} }));

      const { accessToken }: { accessToken: string } = data.data;

      this.setUserAndTokens(this.user, accessToken, this.refreshToken);

      return Promise.resolve(accessToken);
    },
    signOut() {
      const appDataStore = useAppDataStore();

      this.$reset();

      // Also reset any other stores here on sign out action.
      appDataStore.$reset();
    },
  },
  getters: {
    name(): string {
      return this.user?.attributes?.name || "";
    },
    isAuthenticated(): boolean {
      return !!this.accessToken && !!this.refreshToken;
    },
    permissions(): UserPermissionMap {
      return this.user?.permissions || {};
    },
  },
});
